<div *ngIf="eventRequest" class="ate-tile" [class.ate-tile-inactive]="!eventRequestActive">
  <div class="ate-tile-body tw-flex tw-flex-auto tw-flex-col">
    <div class="tw-flex tw-flex-auto tw-items-center tw-gap-4">
      <div class="ate-tile-image">
        <img [src]="eventRequest?.primaryServiceImageURI" alt="Event Request image" />
      </div>
      <div class="ate-tile-header">
        <h3 class="tw-text-lg">{{ eventRequest?.primaryServiceTitle }}</h3>
        <p>
          <span>{{ eventRequest?.eventType | eventType }}</span>
          <span>&nbsp;•&nbsp;</span>
          <span>{{ eventRequest?.eventRequestServiceDate | ordinalDate }}</span>
        </p>
      </div>
    </div>

    <div class="ate-tile-status tw-py-4">
      <app-status-indicator [statusConfig]="status"></app-status-indicator>
    </div>
    <div class="ate-tile-description tw-mb-3 tw-mt-0.5 tw-flex tw-flex-auto">
      <p class="tw-mb-0">
        <span *ngIf="requestDescription.intro" class="tw-font-semibold">{{ requestDescription?.intro }}&nbsp;</span>
        <span class="tw-font-default">{{ requestDescription.main }}</span>
      </p>
    </div>
    <div class="divider"></div>
    <div class="ate-tile-stats tw-flex tw-flex-auto tw-flex-col tw-gap-y-3 tw-px-2 tw-py-4">
      <!-- *** Quotes counter *** -->
      <div class="tw-flex tw-flex-auto tw-items-center tw-justify-between tw-gap-3" [class.stats-highlighted]="quotesHighlight">
        <div class="stats-description tw-flex tw-items-center tw-gap-x-2">
          <ate-icon name="ate-send-v3" class="tw-size-6"></ate-icon>
          <p class="tw-mb-0 tw-font-semibold">Quotes</p>
        </div>
        <div class="counter tw-flex tw-size-6 tw-items-center tw-justify-center">
          <p data-cy="request-quote-count" class="tw-mb-0 tw-font-semibold">{{ eventRequest?.quoteCount }}</p>
        </div>
      </div>

      <!-- *** Interested counter *** -->
      <div
        class="tw-flex tw-flex-auto tw-items-center tw-justify-between tw-gap-3"
        *ngIf="showInterestedCounter"
        [class.stats-highlighted]="eventRequest.liveInterestCount">
        <div class="stats-description tw-flex tw-items-center tw-gap-x-2">
          <ate-icon name="ate-hearts-inverted" class="tw-size-6"></ate-icon>
          <p class="tw-mb-0 tw-font-semibold">Interested Suppliers</p>
        </div>
        <div class="counter tw-flex tw-size-6 tw-items-center tw-justify-center">
          <p data-cy="request-invite-count" class="tw-mb-0 tw-font-semibold">{{ eventRequest?.liveInterestCount }}</p>
        </div>
      </div>

      <!-- *** Invited counter *** -->
      <div class="tw-flex tw-flex-auto tw-items-center tw-justify-between tw-gap-3">
        <div class="stats-description tw-flex tw-items-center tw-gap-x-2">
          <ate-icon name="ate-hearts" class="tw-size-6"></ate-icon>
          <p class="tw-mb-0 tw-font-semibold">Invited Suppliers</p>
        </div>
        <div class="counter tw-flex tw-size-6 tw-items-center tw-justify-center">
          <p data-cy="request-invite-count" class="tw-mb-0 tw-font-semibold">{{ eventRequest?.liveInviteCount }}</p>
        </div>
      </div>
    </div>
    <div class="ate-tile-actions">
      <button data-cy="view-request-button" class="btn-action btn-action-md btn-secondary tw-w-full">
        <span class="tw-mb-0">View Request</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="submitRequest" class="ate-submit-request-tile tw-flex tw-flex-auto tw-flex-col tw-items-center tw-justify-center tw-gap-3">
  <ate-icon name="ate-plus-circle-gradient" class="tw-size-6"></ate-icon>
  <h3>{{ submitRequestText }}</h3>
</div>
