<ate-modal #ateModal is-native="false" cta-text="Reload the Page" (onModalClose)="reloadPage($event)">
  <div slot="body">
    <div class="logo tw-flex tw-flex-auto tw-items-center tw-justify-center">
      <img src="/assets/svg/ate-maintenance.svg" alt="Maintenance image" />
    </div>
    <div class="copy">
      <h1>We've upgraded the experience. <br />Please reload this page.</h1>
      <h3>We've been hard at work improving your experience. Please refresh the page to load the changes.</h3>
      <h3>You’ll lose any unsaved work, press cancel to save your work.</h3>
    </div>
  </div>
</ate-modal>
