/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { ReplaySubject, first } from 'rxjs';
import { QuoteService } from '../../../../features/quotes/services/quote.service';
import { QuoteResponse } from '../../../../features/quotes/services/quote.type';
import { ComponentsModule } from '../../../../ui/components/components.module';
import { ListingItem } from '../../../../ui/components/listing-list/listing-list-item/listing-list-item.component';
import { ModalTypeEnum } from '../../../enums/modal-type.enum';
import { QuoteActionType } from '../../../enums/quote-action-type.enum';
import { SharedModule } from '../../../shared.module';
import { EventTypePipeline } from '../../../pipes/eventType.pipeline';

@Component({
  selector: 'app-decline-quote-modal',
  templateUrl: './decline-quote-modal.component.html',
  styleUrls: ['./decline-quote-modal.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, SharedModule, ComponentsModule, EventTypePipeline],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeclineQuoteModalComponent implements OnInit, AfterViewInit {
  @ViewChildren('footerStep0, footerStep1, footerStep2, footerStep3, footerStep4', {
    read: TemplateRef
  })
  public footerTemplates: QueryList<TemplateRef<any>>;

  @ViewChildren('bodyStep0, bodyStep1, bodyStep2, bodyStep3, bodyStep4', {
    read: TemplateRef
  })
  public bodyTemplates: QueryList<TemplateRef<any>>;

  @Input() isMobile = false;

  @Input() listingItems: ListingItem[];

  @Input() set quote(value: QuoteResponse) {
    this._quote = value;
  }

  get quote(): QuoteResponse {
    return this._quote;
  }
  @Input() set currentListing(value: ListingItem) {
    this._currentListing = value;
  }
  get currentListing(): ListingItem {
    return this._currentListing;
  }

  @Output() onAcceptOtherQuote = new EventEmitter<{
    listingItem: ListingItem;
    modalType: ModalTypeEnum;
  }>();
  @Output() onClose = new EventEmitter();

  public footer$ = new ReplaySubject<TemplateRef<any>>();
  public body$ = new ReplaySubject<TemplateRef<any>>();
  // Modals have their own loading logic, as it needs to be contained in the dialog.
  public isLoading = false;
  public dontDecline = false;
  public isError = false;
  public title = 'Decline Supplier';
  public step = 0;
  public stepTitle = '';
  public stepSubTitle = '';
  public headerIcon = 'close-v2';

  private _quote: QuoteResponse;
  private _currentListing: ListingItem;

  checkboxSelection = JSON.stringify([
    { label: 'I prefer other quotes I’ve received', key: 'others' },
    { label: 'This is out of my budget', key: 'budget' },
    { label: 'I’ve chosen another supplier', key: 'chosen' },
    { label: 'This is not what I’m looking for', key: 'irrelevant' },
    { label: 'I’m no longer looking for someone', key: 'expired' }
  ]);

  confirmRequest: {
    selectReason?: string;
  };

  constructor(
    public quoteService: QuoteService,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (this.isMobile) {
      this.headerIcon = 'chevron-left-v2';
    }
  }

  public ngAfterViewInit(): void {
    this.footer$.next(this.footerTemplates?.toArray()[0]);
    this.body$.next(this.bodyTemplates?.toArray()[0]);
    this.updateStep(0);
    this.cd.detectChanges();
  }

  public updateStep(newStep: number): void {
    this.step = newStep;
    this.footer$.next(this.footerTemplates?.toArray()[this.step]);
    this.body$.next(this.bodyTemplates?.toArray()[this.step]);

    switch (this.step) {
      case 0:
        this.title = 'Decline Supplier';
        this.stepTitle = `Are you sure you want to decline ${this.quote?.listing?.title}?`;
        this.stepSubTitle = `We’ll notify the supplier that their services are not required for your event. `;
        break;
      case 1:
        this.title = 'Supplier Declined';
        this.stepTitle = `Supplier Declined`;
        this.stepSubTitle = `You have declined the following supplier for your <b>${this.quote?.event?.serviceName} Request:</b>`;
        this.currentListing = {
          ...this.currentListing,
          disabled: true,
          selected: false
        };
        break;
      case 2:
        this.title = 'Supplier Declined';
        this.stepTitle = this.isMobile ? `Please tell us why you declined ${this.quote?.listing?.title}` : `Please tell us why you declined`;
        this.stepSubTitle = `${this.quote?.listing?.title} would love to know so they can improve their services in the future.`;
        break;
      case 3:
        this.title = 'Out of Budget';
        this.stepTitle = `Have you considered calling a supplier?`;
        this.stepSubTitle = `We recommend having a call with the supplier to understand the costs involved, and whether there are other options within your budget.`;
        break;
      case 4:
        this.title = 'Your Other Quotes';
        this.stepTitle = `Do you want to accept any quotes from other suppliers?`;
        this.stepSubTitle = `You still have live quotes for your ${this.quote?.event?.serviceName} Request from the following suppliers:`;
        break;
    }
  }

  @HostListener('onValueChanges', ['$event'])
  public onClick(event: { detail: { selectReason?: { value: string } } }) {
    this.confirmRequest = {
      selectReason: event?.detail?.selectReason?.value || this.confirmRequest?.selectReason
    };
  }

  public isOutOfBudget(): void {
    if (this.confirmRequest?.selectReason === 'budget') {
      this.updateStep(3);
      return;
    }

    this.submitDecline();
  }

  public submitDecline(): void {
    this.isLoading = true;
    this.quoteService
      .declineQuotes([this.currentListing.quoteUuid], this.confirmRequest?.selectReason)
      .pipe(first())
      .subscribe({
        next: () => {
          this.isLoading = false;
          if (this.listingItems.length !== 0) {
            this.updateStep(4);
          } else {
            // No more quotes to accept or decline
            this.onClose.emit();
          }
        },
        error: msg => {
          console.error(msg);
          this.isLoading = false;
          this.isError = true;
        }
      });
  }

  public listingItemClick(action: QuoteActionType, listing: ListingItem) {
    if (action === 'book') {
      this.onAcceptOtherQuote.emit({
        listingItem: listing,
        modalType: ModalTypeEnum.ACCEPT_QUOTE
      });
    }

    if (action === 'decline') {
      this.currentListing = {
        ...listing,
        actionButtons: false
      };
      this.updateStep(0);
    }
  }
}
