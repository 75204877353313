import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventRequestSingleType, IAffiliate, IUpsell, NamedUpsells } from '../../../../../shared/services/event-request/event-request.type';
import { DatalayerService } from '../../../../../shared/services/datalayer/datalayer.service';
import { affiliates } from '../affiliates';
import { FirebaseFeatureFlags } from '../../../../../shared/enums/firebase-custom-claims.enum';
import { WindowService } from '../../../../../shared/services/window/window.service';
import { EnhancedFormsLauncherService } from '../../../../../shared/services/enhanced-forms-launcher/enhanced-forms-launcher.service';
import { CommonModule } from '@angular/common';
import { EventTypePipeline } from '../../../../../shared/pipes/eventType.pipeline';

@Component({
  selector: 'app-upsells',
  templateUrl: './upsells.component.html',
  styleUrls: ['./upsells.component.scss'],
  standalone: true,
  imports: [CommonModule, EventTypePipeline],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UpsellsComponent implements OnChanges, OnInit {
  @Input() eventRequest: EventRequestSingleType;
  @Input() upsells: NamedUpsells;
  @Input() eventUpsells: boolean;
  @Input() maxUpsells = 12;

  affiliate: IAffiliate;
  currentRouteRequestUuid: string;
  FirebaseFeatureFlags = FirebaseFeatureFlags;
  upsellsList: IUpsell[];
  upsellsByEventType: IUpsell[];

  constructor(
    private _dataLayer: DatalayerService,
    private route: ActivatedRoute,
    private _windowService: WindowService,
    private _enhancedFormsLauncherService: EnhancedFormsLauncherService
  ) {}

  ngOnInit() {
    this.currentRouteRequestUuid = this.route.snapshot.paramMap.get('eventRequestUuid');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!(changes && changes.eventRequest)) {
      return;
    }

    this.affiliate = this.getAffiliate();

    if (!changes.eventUpsells) {
      return;
    }

    this.upsellsList = this.processUpsells(this.eventUpsells ? this.upsells.all : this.upsells.serviceRelated);
    this.upsellsByEventType = this.processUpsells(this.upsells.eventType);
  }

  upsellEvent(name: string, type: string): void {
    this._dataLayer.sendEvent(name, {
      type,
      eventRequestUuid: this.currentRouteRequestUuid
    });
  }

  /**
   * Opens the V3 form if we have a form definition for that event type. Falls back to the v1 form.
   *
   * Note that the form will automatically redirect to the new event on successful submission.
   */
  openForm(upsell: IUpsell) {
    const eventUuid: string = this.eventRequest.event ? this.eventRequest.event.uuid : this.eventRequest.eventUuid;
    if (upsell.v2FormUuid) {
      this._enhancedFormsLauncherService.launchForm({
        formUuid: upsell.v2FormUuid,
        eventUuid,
        mainEventUuid: eventUuid
      });
    } else {
      this._windowService.open(upsell.url, '_blank');
    }
  }

  /**
   * Removes any duplicates and trims the list of upsells.
   *
   * @param upsells The upsells to process.
   * @returns A deduped and trimmed list of upsells
   */
  private processUpsells(upsells: IUpsell[]): IUpsell[] {
    const uniqueUpsells: IUpsell[] = upsells.filter((item, index, self) => index === self.findIndex(t => t.tid === item.tid));
    const returnedUpsells = uniqueUpsells.slice(0, this.maxUpsells);

    // Make sure we have an even number of upsells, so that we don't orphan upsells on a new row.
    if (returnedUpsells.length % 2 !== 0) {
      return returnedUpsells.slice(0, returnedUpsells.length - 1);
    }
    return returnedUpsells;
  }

  private getAffiliate(): IAffiliate {
    let affiliate = { ...this.eventRequest?.affiliate }; // Create a new object with the same properties
    if (affiliate?.title) {
      const foundAffiliate = affiliates.find(item => item.title === affiliate.title);
      if (foundAffiliate) {
        affiliate = { ...affiliate, ...foundAffiliate }; // Merge properties of found affiliate into the existing one
      }
    }
    return affiliate;
  }
}
