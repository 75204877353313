<div *ngIf="event" class="event-list-item tile-hover tw-flex tw-flex-auto tw-items-center tw-gap-4 tw-rounded-lg tw-p-4">
  <div
    class="eventTypeIcon tw-flex tw-size-14 tw-shrink-0 tw-flex-col tw-items-center tw-justify-center tw-rounded-md lg:tw-size-16 lg:tw-rounded-lg"
    [ngClass]="[event?.iconClass]">
    <ate-icon class="tw-size-7 lg:tw-size-8" *ngIf="event.iconName" [attr.name]="event?.iconName"></ate-icon>
  </div>
  <div class="tw-my-auto tw-block tw-min-w-0">
    <h3 class="tw-mb-1 tw-text-base lg:tw-text-lg">{{ event?.mainEventType | eventType }}</h3>
    <p class="tw-mb-0 tw-truncate tw-text-sm tw-text-slate-600 lg:tw-text-base">{{ description }}</p>
  </div>
  <ate-icon name="ate-chevron-right-v2" class="tw-ml-auto tw-size-5 tw-shrink-0 tw-text-slate-600 lg:tw-size-6"></ate-icon>
</div>
