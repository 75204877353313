import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { IMainEventData } from '../../models/events';
import { Router } from '@angular/router';
import { format, parseISO } from 'date-fns';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { EventTypePipeline } from '../../pipes/eventType.pipeline';

/**
 * A button that displays the event name and icon, along with the event details.
 */
@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrl: './event-item.component.scss',
  standalone: true,
  imports: [CommonModule, SharedModule, EventTypePipeline],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventItemComponent implements OnInit {
  @Input() event: IMainEventData = {
    mainEventTitle: 'New Event',
    mainEventType: 'NEW_EVENT',
    iconName: 'ate-plus',
    iconClass: 'icon_inactive'
  };
  @Input() listSource: string;
  @Output() eventSelected: EventEmitter<IMainEventData> = new EventEmitter<IMainEventData>();

  description: string;

  constructor(private _router: Router) {}

  ngOnInit() {
    if (this.event && this.event.mainEventType !== 'NEW_EVENT') {
      this.description = this.setDescription(this.event);
    }
  }

  setDescription(event: IMainEventData): string {
    const dateFormatted: string = format(parseISO(event.mainEventDate), 'do LLLL yyyy');

    return `${dateFormatted} • ${event.mainEventLocation}`;
  }

  @HostListener('click')
  selectEvent() {
    const { mainEventUuid, mainEventState } = this.event;

    if (this.listSource === 'new-request') {
      this.openRequestQuotes(mainEventUuid);
    } else {
      this.goToEvent(mainEventState, mainEventUuid);
    }

    this.eventSelected.emit(this.event);
  }

  goToEvent(eventState: string, eventUuid: string) {
    this._router.navigateByUrl(`events/${eventState}/${eventUuid}`);
  }

  openRequestQuotes(mainEventUuid: string) {
    if (mainEventUuid) {
      this._router.navigateByUrl(`events/upcoming/${mainEventUuid}/add-request`);
    } else {
      this._router.navigateByUrl(`new-request`);
    }
  }
}
