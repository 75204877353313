import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventRequestDetailsComponent } from './components/event-request-details/event-request-details.component';
import { EventRequestOverviewComponent } from './components/event-request-overview/event-request-overview.component';
import { EventResolver } from '../../shared/resolvers/event.resolver';

const routes: Routes = [
  {
    path: ':eventUuid/:eventRequestUuid',
    resolve: [EventResolver],
    component: EventRequestOverviewComponent,
    children: [
      {
        path: 'quotes',
        component: EventRequestOverviewComponent,
        data: { title: 'Quotes' }
      },
      {
        path: 'bookings',
        component: EventRequestOverviewComponent,
        data: { title: 'Bookings' }
      },
      {
        path: 'invited',
        component: EventRequestOverviewComponent,
        data: { title: 'Invited' }
      },
      {
        path: 'interested',
        component: EventRequestOverviewComponent,
        data: { title: 'Interested' }
      },
      {
        path: 'suggested',
        component: EventRequestOverviewComponent,
        data: { title: 'Suggested' }
      },
      {
        path: 'close',
        loadComponent: () => import('../../shared/standalone/request-actions/request-actions.component').then(m => m.RequestActionsComponent),
        data: { action: 'close' },
        outlet: 'modalOutlet'
      },
      {
        path: '',
        redirectTo: 'suggested',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: ':eventUuid/:eventRequestUuid/details',
    resolve: [EventResolver],
    component: EventRequestDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule {}
