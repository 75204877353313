import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, HostListener, Input } from '@angular/core';
import { WindowService } from '../../services/window/window.service';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { selectApp } from '../../../store/selectors/app.selectors';
import { first } from 'rxjs';
import { IMainEventData } from '../../models/events';
import { MatDialog } from '@angular/material/dialog';
import { EventRequestDialogComponent } from '../dialogs/event-request-dialog/event-request-dialog.component';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';

@Component({
  selector: 'app-information-box',
  templateUrl: './information-box.component.html',
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InformationBoxComponent {
  innerWidth: number;

  @Input() title = '';
  @Input() showNewRequestButton = false;
  @Input() linkHref = '';
  @Input() linkText = '';
  @Input() showDismissButton = false;

  public visible = true;

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private _windowService: WindowService,
    private _store: Store,
    public dialog: MatDialog,
    private _sso: ScrollStrategyOptions
  ) {}

  openRequestQuotes() {
    this._windowService.open(environment.requestQuotesUrl, '_self');
  }

  placeNewRequest() {
    this._store
      .select(selectApp.getEvents)
      .pipe(first())
      .subscribe({
        next: events => {
          events?.length > 0 ? this.openNewRequestDialog(events) : this.openRequestQuotes();
        },
        error: err => {
          console.error('Could not retrieve events from store: ', err);
        }
      });
  }

  private openNewRequestDialog(events: IMainEventData[]) {
    this.dialog.open(EventRequestDialogComponent, {
      width: '500px',
      height: this.innerWidth > 1023 ? 'auto' : '99vh',
      maxWidth: '100vw',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop-dark',
      panelClass: 'event-request-dialog',
      scrollStrategy: this._sso.noop(),
      data: {
        events
      }
    });
  }
}
